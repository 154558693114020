import "./styles.css";

import banner_topo_1 from "../../assets/banner_topo_1.png";
import banner_topo_2 from "../../assets/banner_topo_2.png";
import banner_topo_3 from "../../assets/banner_topo_3.png";
import banner_topo_4 from "../../assets/banner_topo_4.png";
import banner_topo_5 from "../../assets/banner_topo_5.png";
import banner_topo_1Mobile from "../../assets/mobile/banner_topo_1.png";
import banner_topo_2Mobile from "../../assets/mobile/banner_topo_2.png";
import banner_topo_3Mobile from "../../assets/mobile/banner_topo_3.png";
import banner_topo_4Mobile from "../../assets/mobile/banner_topo_4.png";
import banner_topo_5Mobile from "../../assets/mobile/banner_topo_5.png";
import header3 from "../../assets/header3.png";
import header3Mobile from "../../assets/mobile/header3.png";
import iconwpp from "../../assets/iconwpp.png";

import Carousel from "../../components/Carousel";
import CTAForm from "../../components/CTAForm";

const Header = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const arr = [
    {
      image: isMobile ? banner_topo_1Mobile : banner_topo_1,
      title: `É <span class="orange">ADVOGADO</span> E ESTÁ à procura de
            <span class="orange"> Perícia Contábil?</span>`,
      subtitle: `SOMOS ESPECIALISTAS EM PERÍCIA JUDICIAL e Extrajudicial.`,
    },
    {
      image: isMobile ? banner_topo_2Mobile : banner_topo_2,
      title: `<span class="orange">Ações Cível</span> em Geral`,
    },
    {
      image: isMobile ? banner_topo_3Mobile : banner_topo_3,
      title: `Prestação de <span class="orange">Contas Condominial</span>`,
    },
    {
      image: isMobile ? banner_topo_4Mobile : banner_topo_4,
      title: `Cálculos <span class="orange">TRABALHISTAS</span>`,
    },
    {
      image: isMobile ? banner_topo_5Mobile : banner_topo_5,
      title: `Cálculos <span class="orange">PREVIDENCIÁRIOS</span>`,
    },
  ];

  return (
    <header id="header">
      <Carousel arr={arr} isVideo={false} slidesToShow={1} />

      <section className="header2" id="header2">
        <article className="container">
          <h2 className="title">Cálculos Judiciais e Extrajudiciais</h2>

          <div className="line"></div>

          <p className="text">
            Somos especialistas em perícias judiciais e extrajudiciais, cível,
            previdenciárias e trabalhistas. <br />
            <b> Atuamos com escritório de advocacia e advogados individuais.</b>
          </p>

          <CTAForm />
        </article>
      </section>

      <section className="header3" id="header3">
        <article className="container">
          <h2 className="text1 orange">Nossos Principais Serviços</h2>

          <div className="line"></div>

          <h2 className="title">Ações Cível em Geral</h2>

          <p className="subtitle orange">
            Contratos Bancários / Prestações de Contas/ Cumprimento de Sentença
            e muito mais
          </p>

          <div className="gallery">
            <figure className="left">
              <img
                src={isMobile ? header3Mobile : header3}
                alt="Homem realizando cálculos"
              />
            </figure>

            <div className="right">
              <b>
                <p className="text">
                  Auxiliamos também na emissão de Laudos e Pareceres em:
                </p>
              </b>

              <ul>
                <li>Cartão de crédito;</li>
                <li>Conta corrente - cheque especial;</li>
                <li>Contratos em Geral;</li>
                <li>
                  Crédito pessoal, CDC, empréstimos, financiamentos, conta
                  garantida e mais;
                </li>
                <li>Operações de leasing;</li>
                <li>Revisão de outros produtos bancários.</li>
              </ul>

              <CTAForm />
            </div>
          </div>
        </article>
      </section>

      <section id="wpp">
        <a
          href="http://wa.me/+5511984993680?text=Olá,%20Gostaria%20de%20falar%20com%20um%20representante"
          target="_blank"
          rel="noreferrer"
        >
          <figure>
            <img src={iconwpp} alt="Ícone do Whatsapp" />
          </figure>
        </a>
      </section>
    </header>
  );
};

export default Header;
